/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'GlobalStyles',
        import: () => import('@stories/Widgets/Global/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PrimaryHeroBanner',
        import: () => import('@stories/Widgets/PrimaryHeroBanner/PrimaryHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'InnerHeroBanner',
        import: () => import('@stories/Widgets/InnerHeroBanner/InnerHeroBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'EventArticleBanner',
        import: () => import('@stories/Widgets/EventArticleBanner/EventArticleBanner'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageGallery',
        import: () => import('@stories/Widgets/ImageGallery/ImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentAccordion',
        import: () => import('@stories/Widgets/ContentAccordion/ContentAccordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Testimonials',
        import: () => import('@stories/Widgets/Testimonials/Testimonials'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IframeHolder',
        import: () => import('@stories/Widgets/IframeHolder/IframeHolder'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DownloadCards',
        import: () => import('@stories/Widgets/DownloadCards/DownloadCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Content5050',
        import: () => import('@stories/Widgets/Content5050/Content5050'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Signposts',
        import: () => import('@stories/Widgets/Signposts/Signposts'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TeamProfileCarousel',
        import: () => import('@stories/Widgets/TeamProfileCarousel/TeamProfileCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Highlights',
        import: () => import('@stories/Widgets/Highlights/Highlights'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedContent',
        import: () => import('@stories/Widgets/TabbedContent/TabbedContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaWidget',
        import: () => import('@stories/Widgets/MediaWidget/MediaWidget'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsCarousel',
        import: () => import('@stories/Widgets/NewsCarousel/NewsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventsCarousel',
        import: () => import('@stories/Widgets/EventsCarousel/EventsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactCards',
        import: () => import('@stories/Widgets/ContactCards/ContactCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventsListing',
        import: () => import('@stories/Widgets/EventsListing/EventsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturedEventCards',
        import: () => import('@stories/Widgets/FeaturedEventCards/FeaturedEventCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsListing',
        import: () => import('@stories/Widgets/NewsListing/NewsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TableData',
        import: () => import('@stories/Widgets/TableData/TableData'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StickyInfoCTA',
        import: () => import('@stories/Widgets/StickyInfoCTA/StickyInfoCTA'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'LogoListing',
        import: () => import('@stories/Widgets/LogoListing/LogoListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AuthorBlock',
        import: () => import('@stories/Components/AuthorBlock/AuthorBlock'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuoteBlock',
        import: () => import('@stories/Components/QuoteBlock/QuoteBlock'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CategoryList',
        import: () => import('@stories/Components/CategoryList/CategoryList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TeamMemberListing',
        import: () => import('@stories/Widgets/TeamMemberListing/TeamMemberListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Timeline',
        import: () => import('@stories/Widgets/Timeline/Timeline'),
        config: {
            hydrate: 'in-view',
        },
    },
];
